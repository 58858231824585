<template>
  <div class="kt-grid kt-grid--ver kt-grid--root">
    <div
      class="kt-grid kt-grid--hor kt-grid--root kt-login kt-login--v1"
      id="kt_login"
    >
      <div
        class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--desktop kt-grid--ver-desktop kt-grid--hor-tablet-and-mobile"
      >
<!--        &lt;!&ndash;begin::Aside&ndash;&gt;-->
<!--        <div-->
<!--          class="kt-grid__item kt-grid__item&#45;&#45;order-tablet-and-mobile-2 kt-grid kt-grid&#45;&#45;hor kt-login__aside"-->
<!--          :style="{ backgroundImage: `url(${backgroundImage})` } "-->
<!--          style="background-color: #E5F1F5"-->
<!--        >-->
<!--          <div class="kt-grid__item">-->
<!--            <a :href="site_main_url" class="kt-login__logo">-->
<!--              <img src="/assets/media/logos/yelko_logo_black.png" />-->
<!--            </a>-->
<!--          </div>-->
<!--          <div class="kt-grid__item kt-grid__item&#45;&#45;fluid kt-grid kt-grid&#45;&#45;ver kt-hidden kt-visible-md">-->
<!--            <div class="kt-grid__item kt-grid__item&#45;&#45;middle">-->
<!--              <h3 class="kt-login__title">Powering Business, Informations & Websites!</h3>-->
<!--              <h4 class="kt-login__subtitle">-->
<!--                Website designed to build your own website & ecommerce stores-->
<!--              </h4>-->

<!--            </div>-->
<!--          </div>-->
<!--          <div class="kt-grid__item">-->
<!--            <div class="kt-login__info">-->
<!--              <div class="kt-login__copyright">-->
<!--                © 2020 Yelko-->
<!--              </div>-->
<!--               <div class="kt-login__menu">-->
<!--                <a href="https://yelko.com/privacy-policy#/" class="kt-link" target="_blank">Privacy</a>-->
<!--                <a href="https://yelko.com/terms" class="kt-link" target="_blank">Terms</a>-->
<!--                <a href="https://yelko.com/contact" class="kt-link" target="_blank">Contact</a>-->
<!--              </div>-->
<!--             </div>-->
<!--          </div>-->
<!--        </div>-->
<!--        &lt;!&ndash;begin::Aside&ndash;&gt;-->

        <!--begin::Content-->
        <div
          class="kt-grid__item kt-grid__item--fluid kt-grid__item--order-tablet-and-mobile-1 kt-login__wrapper"
        >
          <a :href="site_main_url" class="kt-login__logo">
                          <img src="/assets/media/logos/yelko_logo_black.png" />
                        </a>
                      <router-view></router-view>

                    </div>
                    <!--end::Content-->
      </div>
    </div>
  </div>
</template>

<!-- Load login custom page styles -->
<style lang="scss">
    @import "@/assets/sass/pages/login/login-1.scss";
    @media screen and (min-width: 768px) {
      .kt-visible-md {
        display: flex !important;
      }
    }
</style>

<script>
import { mapState } from "vuex";

export default {
  name: "auth",
  data() {
    return {
      site_main_url: process.env.VUE_APP_SERVER_URL,
    };
  },
  methods: {},
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    }),
    backgroundImage() {
      return process.env.BASE_URL + "assets/media/bg/bg-2.jpg";
    }
  }
};
</script>
